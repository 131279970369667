<template>
  <div
    class="white pt-6 pb-6 pl-8 pr-8 d-flex justify-space-between align-center rounded-lg"
  >
    <h1 class="primary--text font-weight-black">PLANES DE INTERNET</h1>
    <v-btn
      class="font-weight-regular"
      large
      depressed
      color="primary"
      @click="$emit('changeComponent', 'InternetPlansNew')"
      >NUEVO PLAN</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "InternetPlansHead",
};
</script>

<style lang="scss" scoped>
</style>